import React from "react";
import { BlobOne } from "./blobs/BlobOne";
import { BlobTwo } from "./blobs/BlobTwo";
import { BlobThree } from "./blobs/BlobThree";
import { DiagonalCover } from "./DiagonalCover";
import { StraightCover } from "./StraightCover";

interface Props {
    size: HeaderSize,
    straightBottom?: boolean;
    className?: string,
}

export enum HeaderSize {
    SMALL = "small",
    MEDIUM = "medium",
    LARGE = "large",
}

export const ParallaxHeader: React.FC<Props> = ({ children, size, straightBottom = false, className = "" }) => {
    return (
        <header className={`parallax-header ${size}-height ${className}`}>
            <div className="blobs">
                <div className="layer first-layer">
                    <BlobOne fill={"#080a2e08"} />
                </div>
                <div className="layer second-layer">
                    <BlobTwo fill={"#080a2e08"} />
                </div>
                <div className="layer third-layer">
                    <BlobThree fill={"#080a2e08"} />
                </div>
                <div className="layer fourth-layer">
                    <BlobThree fill={"#080a2e08"} />
                </div>
            </div>
            {straightBottom ?
                <StraightCover fill={"#ffffff"} />
                :
                <DiagonalCover fill={"#ffffff"} />
            }
            {children}
        </header>
    )
}